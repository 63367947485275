form.simpleForm {
	display: flex;
	flex-direction: column;
	margin: auto;
	width: 100%;
}

div.simpleFormInputContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 1rem auto;
	width: 100%;
}

div.simpleFormSubmitContainer {
	display: flex;
	align-items: center;
	margin: 1rem auto;
	width: 100%;
}

label.simpleFormLabel {
	flex: 1;
	margin-right: 1rem;
	width: fit-content;
	height: auto;
	font-size: 1.6rem;
	font-weight: 600;
	color: black;
	text-align: right;
}

input.simpleFormInput {
	width: 30rem;
	height: auto;
	margin: auto;
	padding: 1rem;
	font-size: 1.6rem;
	font-weight: 600;
	color: black;
	border: none;
	border-width: 0;
	box-shadow: none;
	background-color: white;
	border: 2px solid black;
	border-radius: 1rem;
}

div.simpleFormInputContainer::after {
	content: "";
	flex: 1;
}

input.simpleFormFileInput {
	margin: auto;
	text-align-last: center;
}

input.simpleFormInput:focus {
	outline: transparent;
}
