header.header {
	display: flex;
	width: 100%;
	height: 10rem;
	padding: 1rem 0;
}

img.logo {
	width: 10rem;
	height: auto;
	margin: auto;
	border-radius: 5rem;
	border: 2px solid black;
}

img.logo:hover {
	width: 12rem;
	border-radius: 6rem;
	cursor: pointer;
}
