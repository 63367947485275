.label {
  color: #ffffff;
  padding-top: 0.5em;
  text-align: center;
  position: relative;
  z-index: 2;
}

.value {
  padding-top: 0.5em;
  text-align: center;
}

:root {
  --motion-ease-func: cubic-bezier(0.43, -0.6, 0.655, 1.65);
  --motion-duration: 0.5s;
}

button.widget {
  position: absolute;
  appearance: none;
  background: transparent;
  border: 0;
  color: #fff;
  cursor: pointer;
  font: inherit;
  font-weight: 500;
  line-height: 1;
  padding: 1em 1.5em;
  width: 300px;
  position: relative;
  transition: filter var(--motion-duration);
  font-family: "Raleway", sans-serif;
  font-size: 2em;
  font-weight: 400;
  letter-spacing: 0.2em;
  /* right: 9%; */
}

button.widget:hover {
  filter: brightness(1.1);
}

button.widget:active {
  filter: brightness(0.9);
}

button.widget > span {
  display: block;
  position: relative;
  transition: transform var(--motion-duration) var(--motion-ease-func);
  z-index: 1;
}

button.widget:hover > span {
  transform: scale(1.05);
}

button.widget:active > span {
  transform: scale(1);
}

button.widget > svg {
  fill: rgba(16, 17, 17, 0.774);
  position: absolute;
  top: 10%;
  left: 10%;
  width: 80%;
  height: 80%;
}

button.widget > svg > path {
  transition: var(--motion-duration) var(--motion-ease-func);
}

button.widget:hover > svg > path {
  d: path("M0,0 C0,-5 100,-5 100,0 C105,0 105,100 100,100 C100,105 0,105 0,100 C-5,100 -5,0 0,0");
  stroke: white;
}

button.widget:active > svg > path {
  d: path("M0,0 C30,10 70,10 100,0 C95,30 95,70 100,100 C70,90 30,90 0,100 C5,70 5,30 0,0");
  stroke: white;
}

button.widget::after {
  transition: transform var(--motion-duration) var(--motion-ease-func);
}
.load {
  /* display: none; */
  width: 40px;
  height: 40px;
}
.load::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-top: 5px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: load-animate 4s ease infinite;
}

@keyframes load-animate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
