div.confirmationModalContainer {
	display: flex;
	position: absolute;
	bottom: 1rem;
	height: 4.5rem;
	width: 40rem;
	border-radius: 3rem;
	background-color: black;
	outline: 2px solid white;
	opacity: 1;
	animation: fade-in 0.25s;
}

@keyframes fade-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

div.confirmationModal {
	display: flex;
	margin: auto;
}

p.confirmationText {
	color: white;
	font-size: 2rem;
	margin: auto;
	text-align: center;
}

div.confirmationCheckContainer {
	display: flex;
	align-items: center;
	background-color: rgb(52, 178, 51);
	height: 3rem;
	width: 3rem;
	border-radius: 3rem;
	outline: 2px solid white;
	margin: 0 1rem;
}
