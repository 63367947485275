div.loginWrapper {
	display: flex;
	width: 40rem;
	height: 40rem;
	margin: auto;
	background-color: black;
	border-radius: 1rem;
	border: 2px solid white;
}

form.loginForm {
	display: flex;
	flex-direction: column;
	margin: auto;
	width: 100%;
}

div.loginInputContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 1rem auto;
	width: 30rem;
}

label.loginLabel {
	font-size: 1.6rem;
	width: 6rem;
}

input.loginInput {
	padding: 0.5rem;
	font-size: 1.6rem;
	color: black;
	background-color: white;
	border: 2px solid #455660;
	border-radius: 1rem;
}

input.loginSubmit {
	width: fit-content;
	padding: 1rem 2rem;
	border-radius: 1rem;
	font-weight: 600;
	font-size: 1.6rem;
	border: 2px solid #455660;
	background-color: white;
}

input.loginSubmit:hover:not(:disabled) {
	cursor: pointer;
}
