div.dropdownMenuContainer {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
}

div.dropdownMenu {
    margin: 2rem;
}

button.dropdownMenuButton {
    display: flex;
    position: relative;
    padding: 0 1rem;
    align-items: center;
    background-color: rgba(0, 0, 0) !important;
    border: 1px solid white !important;
    border-radius: 1rem !important;
    width: 22rem;
}

p.dropdownMenuButtonText {
    padding: 1rem 0;
    width: 16rem;
    font-size: 2rem;
    color: white !important;
    text-align: center;
    margin: auto;
}

button.dropdownMenuButton:hover {
    cursor: pointer;
}

.MuiMenu-paper {
    background-color: rgba(0, 0, 0);
    border: 1px solid white;
    width: 21.8rem;
}
.dropdownMenuItem {
    display: flex;
    font-size: 2rem;
}

.dropdownMenuItem .dropdownMenuItem:hover {
    font-size: 2.2rem;
    background-color: rgba(255, 255, 255, 0.2);
}

p.dropdownMenuItemText {
    margin: auto;
    text-align: center;
    overflow-wrap: break-word;
    white-space: normal;
}
