.react-date-picker {
    display: inline-flex;
    /* position: relative; */
    background-color: white;
    color: black;
    font-size: 1.6rem;
    font-family: Arial;
    width: fit-content;
    margin: auto;
}

.react-date-picker,
.react-date-picker *,
.react-date-picker *:before,
.react-date-picker *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.react-date-picker--disabled {
    background-color: white;
    color: #6d6d6d;
}

.react-date-picker__wrapper {
    display: flex;
    width: 100%;
    align-items: center;
    border: 2px solid black;
}

.react-date-picker__inputGroup {
    width: 100%;
    padding: 0 2px;
    box-sizing: content-box;
    width: fit-content;
}

.react-date-picker__inputGroup__divider {
    padding: 1px 0;
    white-space: pre;
}

.react-date-picker__inputGroup__divider,
.react-date-picker__inputGroup__leadingZero {
    display: inline-block;
}

.react-date-picker__inputGroup__input {
    min-width: 0.54em;
    height: 100%;
    position: unset;
    padding: 0 1px;
    border: 0;
    background: none;
    font: inherit;
    box-sizing: content-box;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
}

.react-date-picker__inputGroup__input::-webkit-outer-spin-button,
.react-date-picker__inputGroup__input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.react-date-picker__inputGroup__input:invalid {
    background: rgba(255, 0, 0, 0.1);
}

.react-date-picker__inputGroup__input--hasLeadingZero {
    margin-left: -0.54em;
    padding-left: calc(1px + 0.54em);
}

.react-date-picker__button {
    border: 0;
    background: transparent;
    padding: 4px 6px;
}

.react-date-picker__button:enabled {
    cursor: pointer;
}

.react-date-picker__button:enabled:hover .react-date-picker__button__icon,
.react-date-picker__button:enabled:focus .react-date-picker__button__icon {
    stroke: #1f9eef;
}

.react-date-picker__button:disabled .react-date-picker__button__icon {
    stroke: #6d6d6d;
}

.react-date-picker__button svg {
    display: inherit;
}

.react-date-picker__calendar {
    width: 350px;
    max-width: 100vw;
    z-index: 1;
}

.react-date-picker__calendar--closed {
    display: none;
}

.react-date-picker__calendar .react-calendar {
    border-width: thin;
}
